<template>
  <v-row justify="center" v-if="active">
    <v-dialog v-model="active" persistent max-width="800px">
      <v-card light>
        <v-card-title>
          <span
            >Neues Rezept -
            <span
              >{{ patient_info.vorname }} {{ patient_info.name }} ({{
                patient_info.geburtsdatum
              }})</span
            ></span
          >
          <v-btn icon @click="patient_dialog = true" class="ml-2">
            <v-icon>mdi-information</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col class="d-flex" cols="5" sm="5">
                <v-select
                  v-model="rezeptHeader.rezeptart"
                  :items="options.rezeptarten[kt_typ]"
                  item-value="id"
                  item-text="bezeichnung"
                  label="Rezeptart"
                  @focus="closeMenus()"
                  prepend-icon="mdi-prescription"
                  class="required"
                  :disabled="irrelevant_fields.rezeptart || isRezeptartDisabled"
                ></v-select>
              </v-col>

              <v-col
                class="d-flex"
                cols="4"
                sm="4"
                v-if="!irrelevant_fields.gebuehrenpflichtig"
              >
                <v-select
                  ref="gebuehrenpflichtig"
                  class="required"
                  v-model="rezeptHeader.gebuehrenpflichtig"
                  :items="options.gebuehrenpflichtig"
                  :hint="gebuehrenpflichtig_hint"
                  persistent-hint
                  label="Gebührenpflichtig"
                  prepend-icon="mdi-cash-multiple"
                  :error-messages="gebuehrenpflichtigErrors"
                  @input="setProperBillableFlag()"
                  @blur="setProperBillableFlag()"
                  :key="billableComponentKey"
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="mt-0" v-if="!irrelevant_fields.discharge_management">
              <v-col class="d-flex" cols="12" sm="3">
                <v-checkbox
                  ref="discharge_management"
                  class="mt-0"
                  v-model="rezeptHeader.discharge_management"
                  label="Entlassmanagement"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-divider class="py-2 mt-4"></v-divider>

            <v-row>
              <v-col cols="12" sm="4" class="pb-0">
                <v-menu
                  v-model="menu.rezeptdatum"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-x
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      ref="rezeptdatum"
                      label="Rezeptdatum"
                      prepend-icon="mdi-calendar"
                      v-model="selections.rezeptdatum"
                      v-on="on"
                      class="required"
                      :error-messages="rezeptdatumErrors"
                      @input="handleInputDateText('rezeptdatum')"
                      @focus="handleFocusDateText('rezeptdatum')"
                      placeholder="TT.MM.JJJJ"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="rezeptHeader.rezeptdatum"
                    @input="handleInputDatePicker('rezeptdatum')"
                    first-day-of-week="1"
                    locale="de-de"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="4" class="pb-0">
                <v-menu
                  v-model="menu.erfassungsdatum"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-x
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      ref="erfassungsdatum"
                      label="Erste Behandlung"
                      prepend-icon="mdi-calendar-start"
                      v-model="selections.erfassungsdatum"
                      v-on="on"
                      class="required"
                      :error-messages="erfassungsdatumErrors"
                      @input="handleInputDateText('erfassungsdatum')"
                      @focus="handleFocusDateText('erfassungsdatum')"
                      placeholder="TT.MM.JJJJ"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="rezeptHeader.erfassungsdatum"
                    @input="handleInputDatePicker('erfassungsdatum')"
                    first-day-of-week="1"
                    locale="de-de"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                class="pb-0"
                v-if="!irrelevant_fields.physiotherapiebeginn"
              >
                <v-menu
                  v-model="menu.physiotherapiebeginn"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-x
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      ref="physiotherapiebeginn"
                      label="Physiotherapiebeginn"
                      prepend-icon="mdi-calendar-alert"
                      v-model="selections.physiotherapiebeginn"
                      v-on="on"
                      clearable
                      @input="handleInputDateText('physiotherapiebeginn')"
                      @focus="handleFocusDateText('physiotherapiebeginn')"
                      placeholder="TT.MM.JJJJ"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="rezeptHeader.physiotherapiebeginn"
                    @input="handleInputDatePicker('physiotherapiebeginn')"
                    first-day-of-week="1"
                    locale="de-de"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row align="center" v-if="!irrelevant_fields.arzt">
              <v-col class="d-flex">
                <v-autocomplete
                  ref="arzt"
                  label="Arzt"
                  v-model="rezeptHeader.arzt"
                  :loading="loading.arzt"
                  :search-input.sync="search.arzt"
                  :items="aerzte"
                  placeholder="Tippen um eine Arztsuche zu starten"
                  prepend-icon="mdi-doctor"
                  :item-text="arzt_text"
                  item-value="id"
                  hide-no-data
                  return-object
                  class="required"
                  :filter="arztFilter"
                  :error-messages="arztErrors"
                  @input="v$.rezeptHeader.arzt.$touch()"
                  @blur="v$.rezeptHeader.arzt.$touch()"
                  @focus="closeMenus()"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                      color="primary"
                      class="text-h5 font-weight-light white--text"
                    >
                      {{ item.name.charAt(0) }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.arzt_num"
                      ></v-list-item-title>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-btn small class="ml-2" outlined @click="addNewDoctor">
                  <v-icon left> mdi-doctor</v-icon>
                  Neuer Arzt
                </v-btn>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
                v-if="!irrelevant_fields.behandlungsbedarf"
              >
                <v-checkbox
                  ref="behandlungsbedarf"
                  class="mt-0"
                  v-model="rezeptHeader.behandlungsbedarf"
                  label="Dringender Behandlungsbedarf"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col class="d-flex" cols="12" sm="3">
                <v-checkbox
                  ref="hausbesuch"
                  class="mt-0"
                  v-model="rezeptHeader.hausbesuch"
                  label="Hausbesuch"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col
                class="d-flex"
                cols="12"
                sm="3"
                v-if="!irrelevant_fields.therapiebericht"
              >
                <v-checkbox
                  class="mt-0"
                  v-model="rezeptHeader.therapiebericht"
                  label="Therapiebericht"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row align="center" v-if="!irrelevant_fields.diagnose">
              <v-col class="d-flex" cols="4">
                <v-autocomplete
                  ref="diagnose_autocomplete"
                  :label="diagnose_label"
                  v-model="selections.diagnose"
                  :loading="loading.diagnose"
                  :search-input.sync="search.diagnose"
                  :items="diagnosen"
                  placeholder="Tippen..."
                  prepend-icon="mdi-stethoscope"
                  item-text="id"
                  item-value="id"
                  hide-no-data
                  return-object
                  chips
                  small-chips
                  clearable
                  :error-messages="diagnoseErrors"
                  @input="handleDiagnoseInput()"
                  @blur="handleDiagnoseInput()"
                  class="required"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.id"></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="item.name"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col
                class="d-flex"
                cols="4"
                v-if="rezeptHeader.rezeptart != 'ZA'"
              >
                <v-checkbox
                  v-model="rezeptHeader.ls_a"
                  label="a"
                  color="primary"
                  :disabled="is_ls_disabled.a"
                  class="pr-5"
                  :error-messages="lsErrors"
                  @input="v$.rezeptHeader.ls_a.$touch()"
                  @blur="v$.rezeptHeader.ls_a.$touch()"
                ></v-checkbox>

                <v-checkbox
                  v-model="rezeptHeader.ls_b"
                  label="b"
                  color="primary"
                  :disabled="is_ls_disabled.b"
                  class="pr-5"
                  :error-messages="lsErrors"
                  @input="v$.rezeptHeader.ls_b.$touch()"
                  @blur="v$.rezeptHeader.ls_b.$touch()"
                ></v-checkbox>

                <v-checkbox
                  v-model="rezeptHeader.ls_c"
                  label="c"
                  color="primary"
                  :disabled="is_ls_disabled.c"
                  class="pr-5"
                  :error-messages="lsErrors"
                  @input="v$.rezeptHeader.ls_c.$touch()"
                  @blur="v$.rezeptHeader.ls_c.$touch()"
                ></v-checkbox>

                <v-checkbox
                  v-model="rezeptHeader.ls_x"
                  label="x"
                  color="primary"
                  :disabled="is_ls_disabled.x"
                  class="pr-5"
                  :error-messages="lsErrors"
                  @input="v$.rezeptHeader.ls_x.$touch()"
                  @blur="v$.rezeptHeader.ls_x.$touch()"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-slide-y-transition :hide-on-leave="true">
              <v-row align="center" v-if="rezeptHeader.ls_x">
                <v-col cols="12" class="pb-0">
                  <v-textarea
                    label="Patientenindividuelle Leitsymptomatik Freitext"
                    v-model="rezeptHeader.ls_x_text"
                    counter="70"
                    rows="1"
                    prepend-icon="mdi-alpha-x-circle"
                    class="required"
                    :error-messages="lsxTextErrors"
                    @input="v$.rezeptHeader.ls_x_text.$touch()"
                    @blur="v$.rezeptHeader.ls_x_text.$touch()"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-slide-y-transition>

            <v-row align="center" v-if="!irrelevant_fields.za_diagnosetext">
              <v-col cols="12">
                <v-textarea
                  label="Zahnarzt Diagnosetext"
                  v-model="rezeptHeader.diagnosetext"
                  counter="70"
                  rows="1"
                  prepend-icon="mdi-alpha-z-circle"
                  class="required"
                  :error-messages="zahnarztDiagnoseErrors"
                  @input="v$.rezeptHeader.diagnosetext.$touch()"
                  @blur="v$.rezeptHeader.diagnosetext.$touch()"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row
              align="center"
              v-if="!irrelevant_fields.frequenz || !irrelevant_fields.icdcodes"
            >
              <v-col class="d-flex" cols="5" v-if="!irrelevant_fields.frequenz">
                <v-text-field
                  ref="frequenz"
                  class="required"
                  v-model.number="rezeptHeader.frequenz"
                  label="Frequenz"
                  prepend-icon="mdi-counter"
                  :error-messages="frequenzErrors"
                  @input="v$.rezeptHeader.frequenz.$touch()"
                  @blur="v$.rezeptHeader.frequenz.$touch()"
                  v-mask="'#'"
                  maxlength="1"
                />
              </v-col>

              <v-col class="d-flex" cols="7" v-if="!irrelevant_fields.icdcodes">
                <v-autocomplete
                  ref="icd10"
                  label="ICD-10"
                  v-model="selections.icd_codes"
                  :loading="loading.icd_codes"
                  :search-input.sync="search.icd_code"
                  :items="icd_codes"
                  :hint="icd_hint"
                  persistent-hint
                  placeholder="ICD10 Suchen..."
                  prepend-icon="mdi-alpha-i-circle"
                  item-text="id"
                  item-value="id"
                  hide-no-data
                  return-object
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  cache-items
                  auto-select-first
                  :class="{
                    required:
                      !irrelevant_fields.icdcodes &&
                      rezeptHeader.rezeptart == 'KK' &&
                      kt_typ == 'KK',
                  }"
                  :error-messages="icd10Errors"
                  @input="handleICD10Input('input')"
                  @blur="handleICD10Input('blur')"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-html="item.id"></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="item.bezeichnung"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row v-if="!irrelevant_fields.privat_zusatztext">
              <v-col cols="12">
                <v-textarea
                  label="Privatrezept - Zusatztext"
                  v-model="rezeptHeader.privat_info_text"
                  rows="1"
                  prepend-icon="mdi-alpha-p-circle"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row align="center" v-if="!irrelevant_fields.genehmigung">
              <v-col class="d-flex" cols="12" sm="6">
                <v-checkbox
                  ref="genehmigung"
                  class="mt-0"
                  v-model="rezeptHeader.genehmigung"
                  label="Genehmigung"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-slide-y-transition :hide-on-leave="true">
              <v-row align="center" v-if="rezeptHeader.genehmigung">
                <v-col class="d-flex" cols="4">
                  <v-text-field
                    ref="genehmigungsnummer"
                    class="required"
                    v-model.number="rezeptHeader.genehmigungsnummer"
                    label="Genehmigungsnummer"
                    prepend-icon="mdi-briefcase-account"
                    :error-messages="genehmigungsnummerErrors"
                    @input="v$.rezeptHeader.genehmigungsnummer.$touch()"
                    @blur="v$.rezeptHeader.genehmigungsnummer.$touch()"
                  />
                </v-col>

                <v-col class="d-flex" cols="4" sm="4">
                  <v-select
                    ref="genehmigungsart"
                    class="required"
                    v-model="rezeptHeader.genehmigungsart"
                    :items="options.genehmigungsart"
                    label="Genehmigungsart"
                    prepend-icon="mdi-briefcase-plus"
                    :error-messages="genehmigungsartErrors"
                    @input="v$.rezeptHeader.genehmigungsart.$touch()"
                    @blur="v$.rezeptHeader.genehmigungsart.$touch()"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="4" sm="4">
                  <v-menu
                    v-model="menu.genehmigungsdatum"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="genehmigungsdatum"
                        label="Genehmigungsdatum"
                        prepend-icon="mdi-briefcase-clock"
                        v-model="selections.genehmigungsdatum"
                        v-on="on"
                        class="required"
                        :error-messages="genehmigungsdatumErrors"
                        @input="handleInputDateText('genehmigungsdatum')"
                        @focus="handleFocusDateText('genehmigungsdatum')"
                        placeholder="TT.MM.JJJJ"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="rezeptHeader.genehmigungsdatum"
                      @input="handleInputDatePicker('genehmigungsdatum')"
                      first-day-of-week="1"
                      locale="de-de"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-slide-y-transition>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  ref="bemerkung"
                  label="Bemerkung"
                  v-model="rezeptHeader.bemerkung"
                  rows="1"
                  prepend-icon="mdi-comment"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeWindow"
            :disabled="buttons_disabled"
            >Zurück</v-btn
          >
          <v-btn
            class="primary-nav-btn" 
            color="primary"
            text
            @click="createRezept"
            :disabled="buttons_disabled"
            :loading="buttons_disabled"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="patient_dialog" width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="content">
            <div v-if="!irrelevant_fields.kk_info" style="margin-bottom: 1em">
              <p>
                <strong>Krankenkasse bzw. Kostenträger</strong>
                <br />
                {{ patient_info.kostentraeger_name }}
                <br />
                ({{ patient_info.kostentraeger_ik_num }})
              </p>
            </div>

            <div style="margin-bottom: 1em">
              <p>
                <strong>Patientendaten</strong>
                <br />
                {{ patient_info.name }}, {{ patient_info.vorname }}
                <br />
                geb. am. {{ geburtsdatum }}
                <br />
                {{ patient_info.strasse }}
                <br />
                {{ patient_info.plz }} {{ patient_info.ort }}
              </p>
            </div>

            <div v-if="!irrelevant_fields.vers_info" style="margin-bottom: 1em">
              <p>
                <strong>Versicherten-Nr.</strong>
                <br />
                {{ patient_info.vers_nummer }}
              </p>
            </div>

            <div v-if="!irrelevant_fields.vers_info">
              <p>
                <strong>Versicherten-Status</strong>
                <br />
                {{ patient_info.vers_status }}
              </p>
            </div>

            <div v-if="!irrelevant_fields.unfalltag_info">
              <p>
                <strong>Unfalltag</strong>
                <br />
                {{ patient_info.unfalltag }}
              </p>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-nav-btn" color="primary" text @click="patient_dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  requiredUnless,
  requiredIf,
  between,
} from "@vuelidate/validators";

const initialData = () => ({
  rezeptHeader: {
    kunde: null,
    rezeptart: null,

    patient: null,
    name: null,
    vorname: null,
    geburtsdatum: null,
    plz: null,
    ort: null,
    strasse: null,

    kostentraeger: null,
    vers_nummer: null,
    vers_status: null,
    unfalltag: null,

    gebuehrenpflichtig: null,
    unfall: false,
    bvg: false,
    hausbesuch: false,
    therapiebericht: false,
    bemerkung: "",
    privat_info_text: "",

    icd_code: "",
    arzt: null,

    rezeptdatum: null,
    erfassungsdatum: null,
    physiotherapiebeginn: null,

    diagnose: null,
    behandlungsbedarf: false,
    langf: false,
    besond: false,

    ls_a: false,
    ls_b: false,
    ls_c: false,
    ls_x: false,
    ls_x_text: "",

    diagnosetext: "",

    frequenz: null,

    genehmigung: false,
    genehmigungsnummer: null,
    genehmigungsart: null,
    genehmigungsdatum: null,
  },

  selections: {
    icd_codes: [],
    diagnose: null,
    rezeptdatum: null,
    erfassungsdatum: null,
    physiotherapiebeginn: null,
    genehmigungsdatum: null,
  },

  loading: {
    icd_codes: false,
    arzt: false,
    diagnose: false,
  },

  search: {
    icd_code: "",
    arzt: "",
    diagnose: "",
  },

  options: {
    rezeptarten: {
      KK: [
        {
          id: "KK",
          bezeichnung: "Krankenkasse",
        },
        {
          id: "ZA",
          bezeichnung: "Zahnarzt",
        },
        {
          id: "KR",
          bezeichnung: "Kur",
        },
      ],
      PP: [
        {
          id: "PP",
          bezeichnung: "Privat",
        },
        {
          id: "VPP",
          bezeichnung: "Vollprivat",
        },
      ],
      BG: [
        {
          id: "BG",
          bezeichnung: "Berufsgenossenschaft",
        },
      ],
      S: [
        {
          id: "KK",
          bezeichnung: "Krankenkasse",
        },
        {
          id: "BW",
          bezeichnung: "Bundeswehr",
        },
        {
          id: "ZA",
          bezeichnung: "Zahnarzt",
        },
        {
          id: "KR",
          bezeichnung: "Kur",
        },
      ],
      PBeaKK: [
        {
          id: "KK",
          bezeichnung: "Krankenkasse",
        },
        {
          id: "ZA",
          bezeichnung: "Zahnarzt",
        },
        {
          id: "KR",
          bezeichnung: "Kur",
        },
      ],
    },

    gebuehrenpflichtig: ["Ja", "Nein", "Teil"],
    genehmigungsart: ["Einzel (B1)", "Langfrist (B2)"],
  },

  irrelevant_fields: {
    kk_info: null,
    rezeptart: null,
    gebuehrenpflichtig: null,
    arzt: null,
    physiotherapiebeginn: null,
    behandlungsbedarf: null,
    therapiebericht: null,
    diagnose: null,
    frequenz: null,
    za_diagnosetext: null,
    icdcodes: null,
    genehmigung: null,
    privat_zusatztext: null,
    unfalltag_info: null,
    vers_info: null,
  },

  menu: {
    rezeptdatum: false,
    erfassungsdatum: false,
    physiotherapiebeginn: false,
    genehmigungsdatum: false,
  },

  buttons_disabled: false,

  diagnose_label: "Diagnose",

  patient_dialog: false,

  afterInputEvent: false,

  billableComponentKey: 0,
});

// Custom Validators
function vPast(value) {
  return !(moment(value).year() < 2021);
}

function vFuture(value) {
  return !moment(value).isAfter(moment());
}

function vBeforeRezeptdatum(value) {
  if (this.rezeptHeader.rezeptdatum) {
    return !moment(value).isBefore(moment(this.rezeptHeader.rezeptdatum));
  }
  return true;
}

function vBeforePhysiotherapiebeginn(value) {
  if (this.rezeptHeader.physiotherapiebeginn) {
    return !moment(value).isBefore(
      moment(this.rezeptHeader.physiotherapiebeginn)
    );
  }
  return true;
}

function vDateDeadline(value) {
  if (
    !["PP", "VPP"].includes(this.rezeptHeader.rezeptart) &&
    this.rezeptHeader.rezeptdatum
  ) {
    let remainingDays = 0;

    if (
      this.rezeptHeader.rezeptart == "BG" &&
      this.rezeptHeader.physiotherapiebeginn
    ) {
      remainingDays = moment(value).diff(
        moment(this.rezeptHeader.physiotherapiebeginn),
        "days"
      );
    } else {
      remainingDays = moment(value).diff(
        moment(this.rezeptHeader.rezeptdatum),
        "days"
      );
    }

    let bg =
      this.rezeptHeader.rezeptart == "BG" &&
      (this.rezeptHeader.behandlungsbedarf
        ? remainingDays > 7
        : remainingDays > 14);
    let rest =
      this.rezeptHeader.rezeptart != "BG" &&
      (this.rezeptHeader.behandlungsbedarf
        ? remainingDays > 14
        : remainingDays > 28);
    return ![bg, rest].some(Boolean);
  }

  return true;
}

function vSunday(value) {
  return moment(value).day() != 0;
}

function vLSSetAny() {
  if (this.rezeptHeader.rezeptart != "ZA" && !this.irrelevant_fields.diagnose) {
    const cond_1 = [
      !this.is_ls_disabled.a,
      !this.is_ls_disabled.b,
      !this.is_ls_disabled.c,
      !this.is_ls_disabled.x,
    ];

    if (cond_1.some(Boolean)) {
      if (!this.is_ls_disabled.a && this.rezeptHeader.ls_a) {
        return true;
      }

      if (!this.is_ls_disabled.b && this.rezeptHeader.ls_b) {
        return true;
      }

      if (!this.is_ls_disabled.c && this.rezeptHeader.ls_c) {
        return true;
      }

      if (!this.is_ls_disabled.x && this.rezeptHeader.ls_x) {
        return true;
      }

      return false;
    }
  }

  return true;
}

function vDischargeManagement(value){
  if (this.rezeptHeader.discharge_management && this.rezeptHeader.rezeptdatum) {
    let diff = moment(value).diff(moment(this.rezeptHeader.rezeptdatum), 'days');
    return diff <= 7;
  }

  return true
}

export default {
  name: "Rezept",

  setup() {
    return { v$: useVuelidate() };
  },

  validations: {
    rezeptHeader: {
      gebuehrenpflichtig: {
        required: requiredUnless(function() {
          return this.irrelevant_fields.gebuehrenpflichtig;
        }),
      },

      frequenz: {
        required: requiredUnless(function() {
          return this.irrelevant_fields.frequenz;
        }),
        between: between(1, 6),
      },

      genehmigungsnummer: {
        required: requiredIf(function() {
          return this.rezeptHeader.genehmigung;
        }),
        maxLength: maxLength(20),
      },

      genehmigungsart: {
        required: requiredIf(function() {
          return this.rezeptHeader.genehmigung;
        }),
      },

      genehmigungsdatum: {
        required: requiredIf(function() {
          return this.rezeptHeader.genehmigung;
        }),
        vPast,
        vFuture,
        vSunday,
      },

      rezeptdatum: {
        required,
        vPast,
        vFuture,
        vSunday,
      },

      erfassungsdatum: {
        required,
        vBeforeRezeptdatum,
        vBeforePhysiotherapiebeginn,
        vDateDeadline,
        vSunday,
        vDischargeManagement,
      },

      arzt: {
        required: requiredUnless(function() {
          return this.irrelevant_fields.arzt;
        }),
      },

      diagnose: {
        required: requiredUnless(function() {
          return this.irrelevant_fields.diagnose;
        }),
      },

      ls_a: {
        vLSSetAny,
      },

      ls_b: {
        vLSSetAny,
      },

      ls_c: {
        vLSSetAny,
      },

      ls_x: {
        vLSSetAny,
      },

      ls_x_text: {
        required: requiredIf(function() {
          return this.rezeptHeader.ls_x;
        }),
        maxLength: maxLength(70),
      },

      icd_code: {
        required: requiredIf(function() {
          return (
            !this.irrelevant_fields.icdcodes &&
            this.kt_typ == "KK" &&
            this.rezeptHeader.rezeptart == "KK"
          );
        }),
      },

      diagnosetext: {
        required: requiredUnless(function() {
          return this.irrelevant_fields.za_diagnosetext;
        }),
        maxLength: maxLength(70),
      },
    },
  },

  data() {
    let init_data = initialData();
    init_data.emptyRezeptHeader = JSON.parse(
      JSON.stringify(init_data.rezeptHeader)
    );

    return init_data;
  },

  computed: {
    active() {
      return this.$store.getters["overlays/rezeptActive"];
    },

    nacherfassungsItem() {
      return this.$store.getters["overlays/getRezeptNacherfassungItem"];
    },

    isVollprivat() {
      return this.nacherfassungsItem.vollprivat;
    },

    isRezeptartDisabled() {
      return this.nacherfassungsItem.patient.kt_typ == "PP";
    },

    privat_info_text() {
      return this.$store.getters["settings/getPrivatInfoText"];
    },

    lastAddedDoctor() {
      return this.$store.getters["overlays/doctorLastAdded"];
    },

    icd_hint() {
      if (this.rezeptHeader.besond) {
        return "Rezept mit besonderem Verordnungsbedarf";
      }

      if (this.rezeptHeader.langf) {
        return "Rezept mit langfristigem Heilmittelbedarf";
      }

      return "";
    },

    gebuehrenpflichtig_hint() {
      let item = this.$store.getters["overlays/getRezeptNacherfassungItem"];
      let text = "";
      if (item && item.befreiungen.length != 0 && this.rezeptHeader.rezeptdatum) {
        for (let befreiung of item.befreiungen) {
          let befreit_von = moment(befreiung.befreit_von);
          let befreit_bis = moment(befreiung.befreit_bis);
          let between = moment(this.rezeptHeader.rezeptdatum).isBetween(
            befreit_von,
            befreit_bis,
            undefined,
            "[]"
          );

          if (between) {
            text =
              "Aktive Zulassungsbefreiung: " +
              befreit_von.format("DD.MM.YYYY") +
              " - " +
              befreit_bis.format("DD.MM.YYYY");
            break;
          }
        }
      }

      return text;
    },

    is_ls_disabled() {
      let relevant = {
        a: true,
        b: true,
        c: true,
        x: true,
      };

      if (this.selections.diagnose) {
        let d = this.selections.diagnose;
        relevant.a = !d.ls_a;
        relevant.b = !d.ls_b;
        relevant.c = !d.ls_c;
        relevant.x = !d.ls_x;
      }

      return relevant;
    },

    kt_typ() {
      let item = this.$store.getters["overlays/getRezeptNacherfassungItem"];
      if (item) {
        return item.patient.kt_typ;
      } else {
        return null;
      }
    },

    patient_info() {
      let item = this.$store.getters["overlays/getRezeptNacherfassungItem"];

      if (item) {
        return item.patient;
      } else {
        return null;
      }
    },

    icd_codes() {
      return this.$store.getters["icd_codes/getICDCodes"];
    },

    aerzte() {
      return this.$store.getters["aerzte/getAerzte"];
    },

    diagnosen() {
      return this.$store.getters["diagnosen/getDiagnosen"];
    },

    geburtsdatum() {
      return moment(this.patient_info.geburtsdatum).format("DD.MM.YYYY");
    },

    // Errors
    gebuehrenpflichtigErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.rezeptHeader.gebuehrenpflichtig;

        if (!entry.$dirty) return errors;

        entry.required.$invalid &&
          errors.push("Es wurde keine Auswahl getroffen!");
      }

      return errors;
    },

    frequenzErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.rezeptHeader.frequenz;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Frequenz fehlt!");

        entry.between.$invalid &&
          errors.push("Frequenz muss zwischen 1 und 6 sein!");
      }

      return errors;
    },

    genehmigungsnummerErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.rezeptHeader.genehmigungsnummer;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Genehmigungsnummer fehlt!");

        entry.maxLength.$invalid &&
          errors.push("Genehmigungsnummer ist zu lang!");
      }

      return errors;
    },

    genehmigungsartErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.rezeptHeader.genehmigungsart;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Genehmigungsart fehlt!");
      }

      return errors;
    },

    genehmigungsdatumErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.rezeptHeader.genehmigungsdatum;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Genehmigungsdatum fehlt!");
      }

      return errors;
    },

    rezeptdatumErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.rezeptHeader.rezeptdatum;
        if (!entry.$dirty) return errors;

        // required
        entry.required.$invalid && errors.push("Rezeptdatum fehlt!");

        // future check
        entry.vFuture.$invalid && errors.push("Datum liegt in der Zukunft!");

        // past check
        entry.vPast.$invalid && errors.push("Datum ist zu alt!");

        // sunday
        entry.vSunday.$invalid && errors.push("Rezeptdatum am Sonntag!");
      }

      return errors;
    },

    erfassungsdatumErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.rezeptHeader.erfassungsdatum;
        if (!entry.$dirty) return errors;

        // required
        entry.required.$invalid && errors.push("Erfassungsdatum fehlt!");

        // before rezeptdatum
        entry.vBeforeRezeptdatum.$invalid &&
          errors.push("Datum liegt vor Rezeptdatum!");

        entry.vBeforePhysiotherapiebeginn.$invalid &&
          errors.push("Datum liegt vor Physiotherapiebeginn!");

        // check day based deadlines
        if (entry.vDateDeadline.$invalid) {
          let grace = null;
          if (this.rezeptHeader.rezeptart == "BG") {
            grace = this.rezeptHeader.behandlungsbedarf ? 7 : 14;
          } else {
            grace = this.rezeptHeader.behandlungsbedarf ? 14 : 28;
          }
          errors.push(`${grace}-Tage Frist überschritten!`);
        }

        // sunday
        entry.vSunday.$invalid && errors.push("Behandlung am Sonntag!");

        // discharge management
        entry.vDischargeManagement.$invalid && errors.push("Erste Behandlung überschreitet Frist von 7 Tagen (Entlassmanagement)!");
      }

      return errors;
    },

    arztErrors() {
      const errors = [];

      if (this.v$ && !this.irrelevant_fields.arzt) {
        const entry = this.v$.rezeptHeader.arzt;

        if (!entry.$dirty) return errors;

        // required
        entry.required.$invalid && errors.push("Arzt fehlt!");
      }
      return errors;
    },

    diagnoseErrors() {
      const errors = [];
      if (this.v$ && !this.irrelevant_fields.diagnose) {
        const entry = this.v$.rezeptHeader.diagnose;

        if (!entry.$dirty) return errors;

        // required
        let missing_msg =
          this.rezeptHeader.rezeptart == "ZA"
            ? "Schlüssel fehlt!"
            : "Diagnose fehlt!";
        entry.required.$invalid && errors.push(missing_msg);
      }
      return errors;
    },

    lsErrors() {
      const errors = [];

      if (this.v$ && !this.irrelevant_fields.diagnose) {
        const entry_a = this.v$.rezeptHeader.ls_a;
        entry_a.vLSSetAny.$invalid && errors.push(" ");
      }

      return errors;
    },

    lsxTextErrors() {
      const errors = [];
      if (this.v$ && this.rezeptHeader.ls_x) {
        const entry = this.v$.rezeptHeader.ls_x_text;

        if (!entry.$dirty) return errors;

        // required
        entry.required.$invalid && errors.push("Beschreibung fehlt!");

        // max length
        entry.maxLength.$invalid && errors.push("Beschreibung ist zu lang!");
      }
      return errors;
    },

    icd10Errors() {
      const errors = [];
      if (this.v$ && !this.irrelevant_fields.icdcodes) {
        const entry = this.v$.rezeptHeader.icd_code;

        if (!entry.$dirty) return errors;

        // required
        entry.required.$invalid && errors.push("Kein ICD-10 Code angegeben!");
      }
      return errors;
    },

    zahnarztDiagnoseErrors() {
      const errors = [];
      if (this.v$ && !this.irrelevant_fields.za_diagnosetext) {
        const entry = this.v$.rezeptHeader.diagnosetext;

        if (!entry.$dirty) return errors;

        // required
        entry.required.$invalid && errors.push("Diagnosetext fehlt!");

        // max length
        entry.maxLength.$invalid && errors.push("Diagnosetext ist zu lang!");
      }
      return errors;
    },
  },

  methods: {
    ...mapActions("overlays", ["closeAndClearRezept"]),
    ...mapActions("nacherfassung", ["createNacherfassungPrescription"]),

    ...mapActions({
      SearchICD: ["icd_codes/Search"],
      SearchArzt: ["aerzte/Search"],
      SearchDiagnose: ["diagnosen/Search"],
    }),

    ...mapMutations("diagnosen", ["clearDiagnosen"]),
    ...mapMutations("aerzte", ["clearAerzte"]),
    ...mapMutations("icd_codes", ["clearICDCodes"]),
    ...mapMutations("overlays", ["openAddDoctor"]),

    addNewDoctor() {
      let defaults = {};
      if (this.search.arzt && !this.rezeptHeader.arzt) {

        // Check if this.search.arzt is of length 9 and has 9 digits
        if (this.search.arzt.length == 9 && /^\d+$/.test(this.search.arzt)) {
          defaults.arzt_num = this.search.arzt;
        } else {
          defaults.arzt_name = "";
        }
      }
      this.openAddDoctor(defaults);
    },

    set_lb_behandlungsbedarf() {
      if (this.selections.icd_codes.length != 0 && this.selections.diagnose) {
        for (let icd of this.selections.icd_codes) {
          let keys = [];
          let heilmittelbereich = this.$store.getters["kunde/getKunde"]
            .heilmittelbereich;

          if (heilmittelbereich == "PHYS" && icd.phys) {
            keys = icd.phys.split("/");
          } else if (heilmittelbereich == "ERGO" && icd.ergo) {
            keys = icd.ergo.split("/");
          } else if (heilmittelbereich == "LOGO" && icd.logo) {
            keys = icd.logo.split("/");
          }

          if (keys.length != 0) {
            let diagnose_match = false;
            let combination_match = false;

            // try to find icd-10 combination match
            if (icd.icd_comb) {
              for (let icd2 of this.selections.icd_codes) {
                if (icd != icd2 && icd2.id == icd.icd_comb) {
                  combination_match = true;
                }
              }
            } else {
              combination_match = true;
            }

            // try to find diagnose gruppe match
            for (let key of keys) {
              if (key == this.selections.diagnose.id.substring(0, key.length)) {
                diagnose_match = true;
              }
            }

            // if both diagnose and icd10 combination key are matched
            if (diagnose_match && combination_match) {
              if (icd.langf) {
                this.rezeptHeader.langf = true;
              }

              if (icd.besond) {
                this.rezeptHeader.besond = true;
              }
              break;
            }
          }
        }
      }
    },

    handleInputDateText(name) {
      if (this.selections[name]) {
        let m = moment(this.selections[name], "DD.MM.YYYY", true);
        if (m.isValid()) {
          this.rezeptHeader[name] = m.format("YYYY-MM-DD");

          setTimeout(() => {
            this.menu[name] = false;
          }, 200);
        } else {
          this.rezeptHeader[name] = null;
        }
      } else {
        this.rezeptHeader[name] = null;
      }

      if (name == "physiotherapiebeginn") {
        this.v$.rezeptHeader.erfassungsdatum.$touch();
      } else {
        this.v$.rezeptHeader[name].$touch();
      }
    },

    closeMenus() {
      var menu_names = [
        "rezeptdatum",
        "erfassungsdatum",
        "physiotherapiebeginn",
      ];

      setTimeout(() => {
        for (const n of menu_names) {
          this.menu[n] = false;
        }
      }, 200);
    },

    handleFocusDateText(name) {
      var menu_names = [
        "rezeptdatum",
        "erfassungsdatum",
        "physiotherapiebeginn",
        "genehmigungsdatum",
      ].filter((e) => e !== name);

      setTimeout(() => {
        if (!this.menu[name]) {
          this.menu[name] = true;
        }
        for (const n of menu_names) {
          this.menu[n] = false;
        }
      }, 200);
    },

    handleInputDatePicker(name) {
      let focus_lkp = {
        rezeptdatum: ["erfassungsdatum"],
        erfassungsdatum: [
          "physiotherapiebeginn",
          "arzt",
          "behandlungsbedarf",
          "hausbesuch",
        ],
        physiotherapiebeginn: ["arzt", "behandlungsbedarf", "hausbesuch"],
        genehmigungsdatum: ["bemerkung"],
      };

      // set selection
      this.selections[name] = moment(this.rezeptHeader[name]).format(
        "DD.MM.YYYY"
      );

      // close picker
      this.menu[name] = false;

      // touch entry
      if (name == "physiotherapiebeginn") {
        this.v$.rezeptHeader.erfassungsdatum.$touch();
      } else {
        this.v$.rezeptHeader[name].$touch();
      }

      // focus next element
      let resolution_order = focus_lkp[name];
      for (var element in resolution_order) {
        if (this.$refs[resolution_order[element]]) {
          let next = resolution_order[element];
          if (next == "behandlungsbedarf" || next == "hausbesuch") {
            this.$refs[next].$refs.input.focus();
          } else {
            this.$refs[next].focus();
          }
          break;
        }
      }
    },

    setProperBillableFlag() {
      if (this.gebuehrenpflichtig_hint.length > 0) {
        this.rezeptHeader.gebuehrenpflichtig = "Nein";
        this.billableComponentKey += 1;
        this.v$.rezeptHeader.gebuehrenpflichtig.$touch()
      }
    },

    resetRezeptHeader() {
      // reset rezept header
      this.rezeptHeader = JSON.parse(JSON.stringify(this.emptyRezeptHeader));
    },

    resetSelections() {
      // reset selections data
      const data = initialData().selections;
      this.selections = data;
    },

    handleDiagnoseInput() {
      // remove search term
      this.search.diagnose = null;

      // touch entry
      this.v$.rezeptHeader.diagnose.$touch();
    },

    handleICD10Input(eventType) {
      // remove search term
      this.search.icd_code = null;

      if (
        eventType == "input" ||
        (this.afterInputEvent && eventType == "blur")
      ) {
        this.afterInputEvent = eventType == "input";

        // deactivate menu
        this.$refs.icd10.$refs.menu.isActive = false;

        // refocus
        this.$refs.icd10.focus();
      }

      // touch entry
      this.v$.rezeptHeader.icd_code.$touch();

      if (this.selections.icd_codes.length > 0) {
        let codes = [];
        for (var icd of this.selections.icd_codes) {
          codes.push(icd.id);
        }

        this.rezeptHeader.icd_code = codes.join(";");
      } else {
        this.rezeptHeader.icd_code = "";
      }
    },

    arztFilter(item, queryText) {
      let byName =
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1;
      let byNum =
        item.arzt_num
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1;

      return byName || byNum;
    },

    arzt_text: (item) => item.arzt_num + " - " + item.name,

    closeWindow() {
      // reset errors
      this.v$.$reset();

      // reset data
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));

      this.closeAndClearRezept();
    },

    createRezept() {
      // touch fields to trigger possible errors
      this.v$.rezeptHeader.$touch();

      // check if there are active errors
      if (this.v$.rezeptHeader.$errors.length == 0) {
        this.buttons_disabled = true;

        this.rezeptHeader.patient = this.patient_info.id;

        this.rezeptHeader.name = this.patient_info.name;
        this.rezeptHeader.vorname = this.patient_info.vorname;
        this.rezeptHeader.geburtsdatum = this.patient_info.geburtsdatum;
        this.rezeptHeader.plz = this.patient_info.plz;
        this.rezeptHeader.ort = this.patient_info.ort;
        this.rezeptHeader.strasse = this.patient_info.strasse;

        this.rezeptHeader.vers_nummer = this.patient_info.vers_nummer;
        this.rezeptHeader.vers_status = this.patient_info.vers_status;

        this.rezeptHeader.arzt = this.rezeptHeader.arzt
          ? this.rezeptHeader.arzt.id
          : null;
        this.rezeptHeader.kostentraeger = this.patient_info.kostentraeger
          ? this.patient_info.kostentraeger
          : null;

        this.rezeptHeader.kunde = this.$store.getters[
          "auth/currentUser"
        ].user_id;

        let data = {
          group_id: this.$store.getters["overlays/getRezeptNacherfassungItem"]
            .id,
          prescription: this.rezeptHeader,
        };

        this.createNacherfassungPrescription(data).then(() => {
          this.buttons_disabled = false;
          this.closeWindow();
        });
      }
    },

    clearLsFields() {
      this.rezeptHeader.ls_a = false;
      this.rezeptHeader.ls_b = false;
      this.rezeptHeader.ls_c = false;
      this.rezeptHeader.ls_x = false;

      this.rezeptHeader.ls_x_text = "";
      // ensure to reset ls_x_text errors
      this.v$.rezeptHeader.ls_x_text.$reset();
    },

    async setRules() {
      let ra = this.rezeptHeader.rezeptart;

      // reset all previously used fields
      this.resetRezeptHeader();

      // ensure rezeptart is set
      this.rezeptHeader.rezeptart = ra;

      // reset selections
      this.resetSelections();

      this.diagnose_label = "Diagnose";

      let kttyp = this.kt_typ;

      if (ra == "KK") {
        // default for kt_typ KK
        this.irrelevant_fields = {
          kk_info: false,
          rezeptart: false,
          gebuehrenpflichtig: false,
          arzt: false,
          physiotherapiebeginn: true,
          behandlungsbedarf: false,
          therapiebericht: false,
          diagnose: false,
          frequenz: false,
          za_diagnosetext: true,
          icdcodes: false,
          genehmigung: false,
          privat_zusatztext: true,
          unfalltag_info: true,
          vers_info: false,
          discharge_management: false
        };

        if (kttyp == "S") {
          this.irrelevant_fields.genehmigung = true;
        } else if (kttyp == "PBeaKK") {
          this.irrelevant_fields.genehmigung = true;
          this.irrelevant_fields.gebuehrenpflichtig = true;
          this.rezeptHeader.gebuehrenpflichtig = "Nein";
        }
      } else if (ra == "BG") {
        // default for kt_typ BG
        this.irrelevant_fields = {
          kk_info: false,
          rezeptart: true,
          gebuehrenpflichtig: true,
          arzt: true,
          physiotherapiebeginn: false,
          behandlungsbedarf: false,
          therapiebericht: true,
          diagnose: true,
          frequenz: false,
          za_diagnosetext: true,
          icdcodes: false,
          genehmigung: true,
          privat_zusatztext: true,
          unfalltag_info: false,
          vers_info: true,
          discharge_management: true,
        };

        this.rezeptHeader.diagnose = "BGBG";
        this.rezeptHeader.therapiebericht = null;
        this.rezeptHeader.gebuehrenpflichtig = "Nein";
        this.$store
          .dispatch("aerzte/findArzt", "999999999")
          .then((response) => {
            this.rezeptHeader.arzt = response.data;
          });
      } else if (ra == "BW") {
        // default for kt_typ BW
        this.irrelevant_fields = {
          kk_info: false,
          rezeptart: false,
          gebuehrenpflichtig: true,
          arzt: true,
          physiotherapiebeginn: true,
          behandlungsbedarf: false,
          therapiebericht: false,
          diagnose: true,
          frequenz: false,
          za_diagnosetext: true,
          icdcodes: false,
          genehmigung: true,
          privat_zusatztext: true,
          unfalltag_info: true,
          vers_info: false,
          discharge_management: true,
        };

        this.rezeptHeader.diagnose = "BWBW";
        this.rezeptHeader.gebuehrenpflichtig = "Nein";
        this.$store
          .dispatch("aerzte/findArzt", "999999999")
          .then((response) => {
            this.rezeptHeader.arzt = response.data;
          });
      } else if (ra == "KR") {
        // default for kt_typ KK
        this.irrelevant_fields = {
          kk_info: false,
          rezeptart: false,
          gebuehrenpflichtig: false,
          arzt: false,
          physiotherapiebeginn: true,
          behandlungsbedarf: false,
          therapiebericht: false,
          diagnose: true,
          frequenz: false,
          za_diagnosetext: true,
          icdcodes: false,
          genehmigung: true,
          privat_zusatztext: true,
          unfalltag_info: true,
          vers_info: false,
          discharge_management: true,
        };

        this.rezeptHeader.diagnose = "KURE";

        if (kttyp == "PBeaKK") {
          this.irrelevant_fields.gebuehrenpflichtig = true;
          this.rezeptHeader.gebuehrenpflichtig = "Nein";
        }
      } else if (ra == "PP" || ra == "VPP") {
        // default for both kt_typ PP and VPP
        this.irrelevant_fields = {
          kk_info: true,
          rezeptart: false,
          gebuehrenpflichtig: true,
          arzt: true,
          physiotherapiebeginn: true,
          behandlungsbedarf: true,
          therapiebericht: true,
          diagnose: true,
          frequenz: true,
          za_diagnosetext: true,
          icdcodes: false,
          genehmigung: true,
          privat_zusatztext: false,
          unfalltag_info: true,
          vers_info: true,
          discharge_management: true,
        };

        this.rezeptHeader.diagnose = "PPPP";
        this.rezeptHeader.therapiebericht = null;
        this.rezeptHeader.gebuehrenpflichtig = "Nein";
        this.rezeptHeader.privat_info_text = this.privat_info_text;
        this.$store
          .dispatch("aerzte/findArzt", "999999999")
          .then((response) => {
            this.rezeptHeader.arzt = response.data;
          });
      } else if (ra == "ZA") {
        // default for kt_typ KK
        this.irrelevant_fields = {
          kk_info: false,
          rezeptart: false,
          gebuehrenpflichtig: false,
          arzt: false,
          physiotherapiebeginn: true,
          behandlungsbedarf: false,
          therapiebericht: false,
          diagnose: false,
          frequenz: false,
          za_diagnosetext: false,
          icdcodes: true,
          genehmigung: true,
          privat_zusatztext: true,
          unfalltag_info: true,
          vers_info: false,
          discharge_management: true,
        };

        this.diagnose_label = "Indikationsschlüssel";

        if (kttyp == "PBeaKK") {
          this.irrelevant_fields.gebuehrenpflichtig = true;
          this.rezeptHeader.gebuehrenpflichtig = "Nein";
        }
      } else {
        // throw error
        throw "Rezeptart not found!";
      }
    },
  },

  watch: {
    active(new_value) {
      if (new_value) {
        // set default rezeptart to first element in options.rezeptarten
        const rezeptart = this.nacherfassungsItem.vollprivat
          ? "VPP"
          : this.options.rezeptarten[this.kt_typ][0].id;

        this.rezeptHeader.rezeptart = rezeptart;
        this.clearDiagnosen();
        this.clearAerzte();
        this.clearICDCodes();
      }
    },

    "selections.diagnose": function(new_value) {
      if (new_value) {
        this.rezeptHeader.diagnose = new_value.id;
      } else {
        this.rezeptHeader.diagnose = null;
      }

      this.set_lb_behandlungsbedarf();
    },

    "selections.icd_codes": function() {
      this.set_lb_behandlungsbedarf();
    },

    "rezeptHeader.rezeptart": function(new_value) {
      // now enforce rules (show only relevant fields in input form)
      if (new_value) {
        this.setRules();

        // clear diagnose cache
        this.clearDiagnosen();

        // clear icd cache
        this.clearICDCodes();

        // clear arzt cache
        this.clearAerzte();

        // reset error messages
        this.v$.rezeptHeader.$reset();
      }
    },

    "rezeptHeader.genehmigung": function(new_value) {
      if (!new_value) {
        // reset genehmigung fields on rezept header
        this.rezeptHeader.genehmigungsnummer = null;
        this.rezeptHeader.genehmigungsart = null;
        this.rezeptHeader.genehmigungsdatum = null;
        this.selections.genehmigungsdatum = null;

        // reset error messages
        this.v$.rezeptHeader.genehmigungsnummer.$reset();
        this.v$.rezeptHeader.genehmigungsart.$reset();
        this.v$.rezeptHeader.genehmigungsdatum.$reset();
      }
    },

    "rezeptHeader.ls_x": function(new_value) {
      if (!new_value) {
        this.rezeptHeader.ls_x_text = "";
        this.v$.rezeptHeader.ls_x_text.$reset();
      }
    },

    "search.icd_code": async function(new_val, old_val) {
      if (new_val && new_val != old_val) {
        if (new_val.length < 3) {
          this.clearICDCodes();
        } else {
          this.loading.icd_codes = true;
          await this.$store.dispatch("icd_codes/Search", new_val);
          this.loading.icd_codes = false;
        }
      }
    },

    "search.arzt": async function(new_val, old_val) {
      if (new_val && new_val != old_val) {
        let key = this.rezeptHeader.arzt
          ? this.rezeptHeader.arzt.arzt_num +
            " - " +
            this.rezeptHeader.arzt.name
          : null;

        if (new_val.length < 3) {
          this.clearAerzte();
        } else if (key != new_val) {
          this.loading.aerzte = true;
          await this.$store.dispatch("aerzte/Search", new_val);
          this.loading.aerzte = false;
        }
      }
    },

    lastAddedDoctor: function(new_val) {
      if (new_val) {
        this.search.arzt = new_val;
      }
    },

    "rezeptHeader.rezeptdatum": function(){
      if (this.gebuehrenpflichtig_hint.length > 0) {
        this.rezeptHeader.gebuehrenpflichtig = "Nein";

        this.billableComponentKey += 1;

        this.v$.rezeptHeader.gebuehrenpflichtig.$touch()
      }
    },

    aerzte: function(new_val) {
      // if only one arzt is found, select it automatically
      // however, only if the arzt_num is the same as the search term
      if (new_val.length == 1 && new_val[0].arzt_num == this.search.arzt) {
        this.rezeptHeader.arzt = new_val[0];
      }
    },

    "search.diagnose": async function(new_val, old_val) {
      if (new_val && new_val != old_val) {
        if (new_val.length == 0) {
          this.rezeptHeader.diagnose = null;
          this.clearLsFields();
        }

        if (new_val.length < 1) {
          this.clearDiagnosen();
        } else {
          this.loading.diagnose = true;
          let params = {
            term: new_val,
            rezeptart: this.rezeptHeader.rezeptart,
          };
          await this.$store.dispatch("diagnosen/Search", params);
          this.selections.diagnose = null;
          this.rezeptHeader.diagnose = null;
          this.clearLsFields();
          this.loading.diagnose = false;
        }
      }
    },
  },

  mounted() {},
};
</script>

<style></style>
