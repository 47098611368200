import axios from "axios";
import moment from "moment";

const state = {
  id_lkp: {},
  patient_lkp: {},

  responseIds: {
    appointments: null,
  },
  appointments: [],
  tempAppointments: [],
  tempAppointmentsLookup: [],

  lastDate: moment().format("YYYY-MM-DD"),
  lastTimeUnit: "month",
};

const getters = {
  getTermine: (state) => {
    return state.appointments;
  },

  getTermin: (state) => (id) => {
    let idx = state.id_lkp[id];
    return state.appointments[idx];
  },

  getTerminebyDay: (state) => {
    let byDay = {};

    for (let i = 0; i < state.appointments.length; i++) {
      const termin = state.appointments[i];
      let start_date = moment(termin.start).utc(true);
      let end_date = moment(termin.end).utc(true);

      while (start_date.isSameOrBefore(end_date)) {
        let date_key = start_date.format("DD.MM.YYYY");
        Object.prototype.hasOwnProperty.call(byDay, date_key)
          ? byDay[date_key].add(i)
          : (byDay[date_key] = new Set([i]));
        start_date.add(1, "d");
      }
    }

    return byDay;
  },

  getTerminebyHour: (state) => {
    let byHour = {};

    for (let i = 0; i < state.appointments.length; i++) {
      const termin = state.appointments[i];
      let start_date = moment(termin.start).utc(true);
      let end_date = moment(termin.end).utc(true);

      while (start_date.isSameOrBefore(end_date, "hour")) {
        let hour_key = start_date.format("HH");
        Object.prototype.hasOwnProperty.call(byHour, hour_key)
          ? byHour[hour_key].add(i)
          : (byHour[hour_key] = new Set([i]));
        start_date.add(1, "h");
      }
    }

    return byHour;
  },

  getTerminebyMitarbeiter: (state) => {
    let byMitarbeiter = {};

    for (let i = 0; i < state.appointments.length; i++) {
      const termin = state.appointments[i];
      let mitarbeiter = termin.mitarbeiter;

      // if start_date set known add id to set otherwise create new set
      Object.prototype.hasOwnProperty.call(byMitarbeiter, mitarbeiter)
        ? byMitarbeiter[mitarbeiter].add(i)
        : (byMitarbeiter[mitarbeiter] = new Set([i]));
    }

    return byMitarbeiter;
  },

  getAllDaybyMitarbeiter: (state) => {
    let allDaybyMitarbeiter = {};

    for (let i = 0; i < state.appointments.length; i++) {
      const termin = state.appointments[i];
      let mitarbeiter = termin.mitarbeiter;
      if (termin.ganztag) {
        let start_date = moment(termin.start).utc(true);
        let end_date = moment(termin.end).utc(true);

        let allday_dates = [];
        while (start_date.isSameOrBefore(end_date)) {
          allday_dates.push(start_date.format("DD.MM.YYYY"));
          start_date.add(1, "d");
        }

        Object.prototype.hasOwnProperty.call(allDaybyMitarbeiter, mitarbeiter)
          ? allDaybyMitarbeiter[mitarbeiter].add(allday_dates)
          : (allDaybyMitarbeiter[mitarbeiter] = new Set(allday_dates));
      }
    }

    return allDaybyMitarbeiter;
  },
};

const actions = {
  // ! MOVE Create Termine here

  async updateTerminStatus({ rootGetters, dispatch }, data) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      let response = await axios.post("termin_status/", data, config);

      // Update appointments in state
      await dispatch("getAppointmentData");

      return response.data;
    } catch (error) {
      throw "Some error " + error;
    }
  },

  async getTerminZettel({ rootGetters }, data) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
      responseType: "arraybuffer",
    };

      // add timestamp dummy to ensure get request is not cached
      let timestamp = new Date().getTime();
      let url = "";
      if ("gruppen_id" in data) {
        url = "files/termine/" + data.gruppen_id.toString() + "/?type=single";
      } else if ("patient_id" in data) {
        url = "files/termine/" + data.patient_id.toString() + "/?type=patient";
      }

      url += "&timestamp=" + timestamp;

      return axios.get(url, config).then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = data.pdf_name;
        link.click();

        return response;
      }).catch((error) => {
        return error.response;
      })

  },

  async updateTerminEntry({ rootGetters, dispatch }, termin) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    const url = "termine/" + termin.id + "/";
    const termin_data = JSON.parse(JSON.stringify(termin));

    if (termin.category) {
      termin_data.mitarbeiter = rootGetters[
        "mitarbeiter/getMitarbeiterByCategory"
      ](termin.category).id;
    }

    if (termin.start) {
      termin_data.start = moment(termin.start)
        .utc(true)
        .toISOString();
    }

    if (termin.end) {
      termin_data.end = moment(termin.end)
        .utc(true)
        .toISOString();
    }

    // fix start and end dates
    if (termin.termintyp == "Behandlung") {
      termin_data.color = "";
    }

    try {
      let response = await axios.put(url, termin_data, config);

      // Update appointments in state
      await dispatch("getAppointmentData");

      return response.data;
    } catch (error) {
      throw "Some error " + error;
    }
  },

  async updateAppointments({ rootGetters, dispatch }, data) {
    // eslint-disable-line no-unused-vars

    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      await axios.post("termine/update/", data, config); // eslint-disable-line no-unused-vars

      // Update appointments in state
      await dispatch("getAppointmentData");

      return {};
    } catch (error) {
      // TODO: Improve error handling
      return error.response.data;
    }
  },

  async deleteAppointment({ rootGetters, dispatch }, id) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      let response = await axios.delete("termine/" + id + "/", config);

      // Update appointments in state
      await dispatch("getAppointmentData");

      return response.data;
    } catch (error) {
      throw "Some error " + error;
    }
  },

  async deleteTerminGruppe({ rootGetters, dispatch }, id) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      // Delete termingruppe (and all related objects) from database
      await axios.delete("termine/gruppe/" + id + "/", config);

      // Update appointments in state
      await dispatch("getAppointmentData");
    } catch (error) {
      throw "Some error " + error;
    }
  },

  async queryTermine({ rootGetters }, params) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
      params: params,
    };

    try {
      const response = await axios.get("termine/", config);
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  },

  async getAppointmentSeries({ rootGetters }, data) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      const response = await axios.post("appointments/series/", data, config);
      return response.data;
    } catch (error) {
      throw "Some error " + error;
    }
  },

  async splitAppointments({ rootGetters, dispatch }, data) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    const groupId = data.groupId;
    delete data.groupId;

    try {
      const response = await axios.post("termine/gruppe/" + groupId + "/split/", data, config);

      // Update appointments in state
      await dispatch("getAppointmentData");

      return response.data;
    } catch (error) {
      throw "Some error " + error;
    }
  },

  async getAppointmentData({ rootGetters, commit, state }, params = null) {
    // Default params (either the last date and time unit or todays date and month as default)
    if (!params) {
      params = {
        date: state.lastDate,
        timeUnit: state.lastTimeUnit,
      };
    } else {
      await commit("updateDateAndTimeUnit", params);
    }

    let config = {
      headers: rootGetters["auth/authHeaders"],
      params: params,
    };

    try {
      const response = await axios.get("appointments/", config);

      if (response.data.responseId != state.responseIds.appointments) {
        // set Appointment data
        state.responseIds.appointments = response.data.responseId;
        commit("setAppointments", response.data.results);
        commit("reAddTemporaryAppointments");
      }
    } catch (error) {
      throw "Some error " + error;
    }
  },

  async getAppointmentGroup({ rootGetters }, id) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      const response = await axios.get("termine/gruppe/" + id + "/", config);
      return response.data;
    } catch (error) {
      throw "Some error " + error;
    }
  },

  async updateAppointmentGroup({ rootGetters, dispatch}, data) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      await axios.put("termine/gruppe/" + data.id + "/", data, config);

      // Update appointments in state
      await dispatch("getAppointmentData");

    } catch (error) {
      throw "Some error " + error;
    }
  },

  async checkInvalidAppointments({ rootGetters }) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      const response = await axios.get("appointments/invalid-exist/", config);
      return response.data;
    } catch (error) {
      throw "Some error " + error;
    }
  }

};

const mutations = {
  updateDateAndTimeUnit(state, data) {
    state.lastDate = data.date;
    state.lastTimeUnit = data.timeUnit;
  },

  setAppointments(state, data) {
    state.appointments = data;
  },

  setAppointmentRequestId(state, id) {
    state.responseIds.appointments = id;
  },

  addTemporaryAppointment(state, appointment) {
    state.appointments.push(appointment);
    state.tempAppointments.push(appointment);

    let last_index = state.appointments.length - 1;
    state.tempAppointmentsLookup.push(last_index);
  },

  addTemporaryAppointments(state, rawAppointments) {
    // This mutation should only be used when adding appointments to the state
    // Do not use this mutation to update appointments

    for (let entry of rawAppointments) {
      let [h_a, m_a] = entry.start_time.split(":");

      const duration = entry.behandlungen.reduce(
        (acc, b) => acc + b.zeiteinheit,
        0
      );
      const s = moment(entry.start_date)
        .hour(h_a)
        .minute(m_a)
        .utc(true);
      const e = moment(s).add(duration, "minutes");

      const patient = entry.patient ? { patient: entry.patient} : null

      let appointment = {
        id: entry.id,
        start: s.format("YYYY-MM-DDTHH:mm"),
        end: e.format("YYYY-MM-DDTHH:mm"),
        category: entry.mitarbeiter_str,
        status: "Temporär",
        termintyp: "Behandlung",
        name: "Neuer Termin",
        patient_data: patient,
      };

      state.appointments.push(appointment);
      state.tempAppointments.push(appointment);
      let last_index = state.appointments.length - 1;
      state.tempAppointmentsLookup.push(last_index);
    }
  },

  reAddTemporaryAppointments(state) {
    if (state.tempAppointments.length > 0) {
      // clear index entries of tempAppointmentsLookup as the appointments will be readded
      state.tempAppointmentsLookup = [];
      for (let index = 0; index < state.tempAppointments.length; index++) {
        state.appointments.push(state.tempAppointments[index]);
        let last_index = state.appointments.length - 1;
        state.tempAppointmentsLookup.push(last_index);
      }
    }

  
  },

  removeTemporaryAppointments(state) {
    // ensure tempAppointmentsLookup is sorted in descending order
    state.tempAppointmentsLookup.sort(function(a, b) {
      return b - a;
    });

    // remove temp_termine from termine
    for (let index = 0; index < state.tempAppointmentsLookup.length; index++) {
      state.appointments.splice(state.tempAppointmentsLookup[index], 1);
    }

    // reset tempAppointmentsLookup
    state.tempAppointments = [];
    state.tempAppointmentsLookup = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
